import { Link } from "react-router-dom";

const MainHeader = () => {
  return (
    <header className="main__header">
      <div className="container main__header-container">
        <div className="main__header-left">
          <div className="main__header-circle"></div>
          <div className="main__header-image">
            {/* <img src="" alt="" /> */}
          </div>
        </div>
        <div className="main__header-right">
          <h4>Welcome to Angelo Group Technologies</h4>
          <h1>Your Partner in Digital Transformation and IT Excellence</h1>
          <p>
            At Angelo Group Technologies, our aim is to empower businesses
            through innovative technology solutions that drive digital
            transformation.
          </p>
          <Link to="/about" className="btn lg">
            Read More
          </Link>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
