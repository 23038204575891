import "./services.css";
import serv1 from "../../images/service1.jpg";
import serv2 from "../../images/service2.jpg";
import serv3 from "../../images/service3.jpg";
import serv4 from "../../images/service4.jpg";
import serv5 from "../../images/service5.jpg";
import serv6 from "../../images/service6.jpg";
import serv7 from "../../images/service7.jpg";
import serv8 from "../../images/service8.jpg";
import serv9 from "../../images/service9.jpg";
import serv10 from "../../images/service10.jpg";
import serv11 from "../../images/service11.jpeg";
import serv12 from "../../images/service121.jpg";

const services = [
  {
    title: "IT Infrastructure Assessment",
    description:
      "Comprehensive review of existing IT systems, hardware, software, and network infrastructure. Identify bottlenecks and inefficiencies, recommending improvements to enhance performance and reliability.",
    image: serv1,
  },
  {
    title: "Strategic IT Planning",
    description:
      "Create a strategic IT roadmap aligned with your business goals, including short-term and long-term initiatives. Advise on integrating new technologies to enhance capabilities and achieve competitive advantage.",
    image: serv2,
  },
  {
    title: "Cybersecurity and Risk Management",
    description:
      "Conduct thorough security audits to identify vulnerabilities and threats. Develop and implement risk management strategies to protect against data breaches, cyber-attacks, ensuring compliance with industry standards.",
    image: serv3,
  },
  {
    title: "Cloud Services and Migration",
    description:
      "Develop a cloud strategy tailored to your business needs, whether it's public, private, or hybrid cloud. Assist with seamless migration of applications and data to the cloud, minimizing downtime.",
    image: serv4,
  },
  {
    title: "Data Management and Analytics",
    description:
      "Develop a data strategy that encompasses data governance, quality, and lifecycle management. Implement BI tools and practices to turn data into actionable insights for decision making.",
    image: serv5,
  },
  {
    title: "Managed IT Services",
    description:
      "Offer managed services to handle day-to-day IT operations, ensuring efficiency. Provide round-the-clock support to address any IT issues promptly and effectively.",
    image: serv6,
  },
  {
    title: "Cost Optimization Strategies",
    description:
      "Evaluate current IT expenditure and identify areas for cost reduction. Recommend cost-saving measures without compromising quality or performance.",
    image: serv7,
  },
  {
    title: "Performance Optimization",
    description:
      "Identify performance bottlenecks in the existing IT infrastructure. Recommend and implement improvements to enhance system performance and reliability.",
    image: serv8,
  },
  {
    title: "Technology Integration",
    description:
      "Advise on integrating new and existing technologies to enhance IT capabilities. Ensure seamless integration of systems for improved operational efficiency.",
    image: serv9,
  },
  {
    title: "Digital Transformation",
    description:
      "Guide your organization through digital transformation for innovative process improvements. Leverage cutting-edge technologies for competitive advantage and growth.",
    image: serv10,
  },
  {
    title: "Website Development",
    description:
      "Build a powerful online presence with custom website development. Create user-friendly, responsive websites that drive engagement, boost brand visibility, and support business growth.",
    image: serv11,
  },
  {
    title: "IT and Business Training",
    description:
      "Equip your team with essential IT and business skills for enhanced productivity and operational efficiency. Master the latest tools and strategies to drive organizational success and growth.",
    image: serv12,
  },
];

const Services = () => {
  return (
    <div className="services-container">
      <h2>Our Services</h2>

      <div className="services-wrapper">
        <div className="services-grid">
          {services.map((service, index) => (
            <div className="service-card" key={index}>
              <img
                src={service.image}
                alt={service.title}
                className="service-image"
              />
              <div className="service-info">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
