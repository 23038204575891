import { BrowserRouter, Routes,Route } from 'react-router-dom'
import Home from './pages/home/home'
import About from './pages/about/about'
import Contacts from './pages/contacts/contacts'
import Services from './pages/services/services'
import Team from './pages/team/team'
import NotFound from './pages/notFound/notFound'
import Navbar from './components/Navbar'
import './index.css'
import Footer from './components/Footer'

const App = () => {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route index element={<Home/>}/>
        <Route path='about' element ={<About/>}/>
        <Route path='services' element ={<Services/>}/>
        <Route path='team' element ={<Team/>}/>
        <Route path='contact' element ={<Contacts/>}/>
        <Route path='*' element={<NotFound/>}/>

      </Routes>
      <Footer/>



    </BrowserRouter>
  )
}

export default App