import { RiDatabase2Fill } from "react-icons/ri";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { SiFrontendmentor } from "react-icons/si";
import { GrTechnology } from "react-icons/gr";
import { FaDev } from "react-icons/fa";
import { GoGitCompare } from "react-icons/go";

const Choose = () => {
  const reasons = [
    {
      title: "Comprehensive IT Solutions",
      description:
        "From IT infrastructure assessments to strategic planning and performance optimization, we offer a full spectrum of IT services to meet your business needs.",
      icon: <RiDatabase2Fill className="card-icon" />,
    },
    {
      title: "Expertise and Innovation",
      description:
        "Our team of experienced professionals leverages cutting-edge technologies and industry best practices to drive innovation and maintain a competitive edge.",
      icon: <SiAmazonsimpleemailservice className="card-icon" />,
    },
    {
      title: "Proactive Cybersecurity",
      description:
        "We prioritize cybersecurity and risk management, implementing robust measures to protect your IT infrastructure from data breaches and cyber threats.",
      icon: <SiFrontendmentor className="card-icon" />,
    },
    {
      title: "Seamless Cloud Integration",
      description:
        "Our tailored cloud services and migration strategies ensure minimal downtime and maximum performance, with ongoing management to support your business operations.",
      icon: <GoGitCompare className="card-icon" />,
    },
    {
      title: "Data-Driven Insights",
      description:
        "Through advanced analytics and business intelligence tools, we transform your data into actionable insights, empowering strategic decision-making and business growth.",
      icon: <GrTechnology className="card-icon" />,
    },
    {
      title: "Reliable Support and Cost Efficiency",
      description:
        "We offer managed IT services with round-the-clock support, ensuring continuous and reliable service delivery while optimizing costs and maximizing value.",
      icon: <FaDev className="card-icon" />,
    },
  ];

  return (
    <div className="why-choose-us-wrapper">
      <section className="why-choose-us">
        <h2>Why Choose Us</h2>
        <p>
          Choosing Angelo Consulting Group means partnering with a team that is
          dedicated to your success. We prioritize integrity, reliability, and
          client satisfaction above all else. Our goal is not just to solve
          problems but to build lasting relationships that foster mutual growth
          and prosperity.
        </p>
        <div className="card-container">
          {reasons.map((reason, index) => (
            <div className="card" key={index}>
              <div className="card-body">
                {reason.icon}
                <h5 className="card-title">{reason.title}</h5>
                <p className="card-text">{reason.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Choose;
