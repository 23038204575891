import { FaUserTie, FaLightbulb, FaShieldAlt, FaCog } from 'react-icons/fa';

const StrategicFocus = () => {
    const focuses = [
        {
          title: "Client-Centric Approach",
          description: "Tailor solutions to meet specific client needs, ensuring alignment with business objectives and growth strategies.",
          icon: <FaUserTie className="focus-icon" />
        },
        {
          title: "Innovation and Adaptability",
          description: "Stay abreast of technological advancements to deliver cutting-edge solutions and maintain competitiveness in the market.",
          icon: <FaLightbulb className="focus-icon" />
        },
        {
          title: "Security and Compliance",
          description: "Prioritize robust cybersecurity measures and adherence to regulatory standards to protect client data and mitigate risks effectively.",
          icon: <FaShieldAlt className="focus-icon" />
        },
        {
          title: "Operational Efficiency",
          description: "Enhance operational efficiency through proactive management, optimized IT infrastructure, and strategic planning.",
          icon: <FaCog className="focus-icon" />
        }
      ];
    
      return (
        <div className="strategic-focus-wrapper">
          <section className="strategic-focus">
            <h2>Strategic Focus</h2>
            <div className="focus-card-container">
              {focuses.map((focus, index) => (
                <div className="focus-card" key={index}>
                  <div className="focus-card-body">
                    {focus.icon}
                    <h5 className="focus-card-title">{focus.title}</h5>
                    <p className="focus-card-text">{focus.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      );
    };


export default StrategicFocus