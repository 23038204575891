import './contacts.css'
import { MdEmail } from "react-icons/md";
import { useRef } from 'react';
import emailjs from 'emailjs-com'

const Contacts = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_w8juic9', 'template_9ijacpj', form.current, 'CGMbFK6y3uXUG_rOd')
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          alert('Message sent successfully!');
          form.current.reset();
        },
        (error) => {
          console.error('Failed to send email:', error);
          alert('Failed to send message. Please try again later.');
        }
      );
  };

  return (
    <section className="contact-section">

      <h5>Get In Touch</h5>
      <h2>Contact Us</h2>


      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdEmail  className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>info@flyangelo.co.za</h5>
            <a href="mailto:info@flyangelo.co.za">Send a message</a>
          </article>

        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows='7' placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'> Send Message</button>
        </form>
      </div>
    </section>

  )
}

export default Contacts