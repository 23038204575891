import {SiOpenaigym} from 'react-icons/si'



export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Services",
        path: '/services'
    },
    {
        name: "Team",
        path: '/team'
    },
    
    {
        name: "Contact",
        path: '/contact'
    }
]







export const choose = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Comprehensive IT Solutions",
        info: "From IT infrastructure assessments to strategic planning and performance optimization,  we offer a full spectrum of IT services to meet your business needs.",
        path: "/choose/111"
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Expertise and Innovation",
        info: "Our team of experienced professionals leverages cutting-edge technologies and industry  best practices to drive innovation and maintain a competitive edge.",
        path: "/choose/222"
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "Proactive Cybersecurity",
        info: "We prioritize cybersecurity and risk management, implementing robust measures to protect your IT infrastructure from data breaches and cyber threats.",
        path: "/choose/333"
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Seamless Cloud Integration",
        info: "Our tailored cloud services and migration strategies ensure minimal downtime and maximum performance, with ongoing management to support your business operations.",
        path: "/choose/444"
    },
    {
        id: 5,
        icon: <SiOpenaigym/>,
        title: "Data-Driven Insights:",
        info: "Through advanced analytics and business intelligence tools, we transform your data into actionable insights, empowering strategic decision-making and business growth.",
        path: "/choose/555"

    },

    {
    id: 6,
        icon: <SiOpenaigym/>,
        title: "Reliable Support and Cost Efficiency",
        info: "We offer managed IT services with round-the-clock support, ensuring continuous and reliable service delivery while optimizing costs and maximizing value.",
        path: "/choose/666"

    }
]








    















const CEO = require('./images/CEO.jpg')
const CTO = require('./images/CTO.jpg')



export const team = [
    {
        id: 1,
        image: CEO,
        name: 'Saint Anuma',
        job: 'Chief Executive Officer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 2,
        image: CTO,
        name: 'Obianuju Anuma',
        job: 'Chief Technology Officer',
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    
]
