import Header from "../../components/Header";
import "./about.css";
import React from "react";
import StoryImage from "../../images/logo5.png";

const about = () => {
  return (
    <div className="about-page">
      <Header title="ANGELO GROUP TECHNOLOGIES">
        Welcome to Angelo Consulting Group, your trusted partner in navigating
        the complexities of the digital age. We are a premier technology
        consulting firm dedicated to empowering businesses with innovative
        solutions that drive growth, efficiency, and a sustainable competitive
        edge.
      </Header>

      <section className="about__story">
        <container className="about__story-container">
          <div className="about__section-image">
            <img src={StoryImage} alt="My story" />
          </div>
          <div className="about__section-content">
            <h1>About Us</h1>
            <p>
              At Angelo Consulting Group, we bring together a diverse team of
              experts who are passionate about technology and its transformative
              power. With decades of combined experience across various
              industries, our consultants possess deep knowledge and a proven
              track record in delivering results.
            </p>
            <p>
              We specialize in providing strategic guidance and hands-on
              implementation across a wide range of technology domains. Whether
              you're looking to optimize your IT infrastructure, harness the
              potential of data analytics, or embark on a digital transformation
              journey, we tailor our solutions to meet your specific needs.
            </p>
            <p>
              {" "}
              Our approach is rooted in collaboration, transparency, and a
              commitment to excellence. We work closely with your team to
              understand your challenges, identify opportunities, and co-create
              customized strategies that align with your business objectives.
              Through continuous innovation and rigorous execution, we ensure
              that our solutions not only meet but exceed your expectations.
            </p>
          </div>
        </container>
      </section>

      <section className="about__vision">
        <container className="about__vision-container">
          <div className="about__section-content vision-content">
            <h1>Our Vision</h1>
            <p>
              To be the catalyst for transformative change, empowering
              organizations worldwide with cutting-edge technology solutions
              that drive sustainable growth and innovation."
            </p>
          </div>
          <div className="about__vision-image"></div>
        </container>
      </section>

      <section className="about__mission">
        <container className="about__mission-container">
          <div className="about__mission-image mission-image"></div>
          <div className="about__section-content">
            <h1>Our Mision</h1>
            <p>
              At Angelo Consulting Group, our mission is to leverage our
              expertise in technology and strategy to deliver exceptional value
              to our clients. We strive to:
            </p>
            <ul>
              <li>
                Empower businesses to achieve their full potential through
                innovative and practical technology solutions.
              </li>
              <li>
                Partner with our clients as trusted advisors, understanding
                their unique challenges and collaborating to achieve shared
                goals.
              </li>
              <li>
                Continuously innovate and adapt to emerging technologies and
                market trends to stay ahead of the curve.
              </li>
              <li>
                Deliver excellence in every engagement, ensuring measurable
                outcomes that exceed expectations.
              </li>
              <li>
                Build long-lasting relationships based on integrity,
                transparency, and mutual respect.
              </li>
            </ul>
          </div>
        </container>
      </section>

      <section className="our-approach">
        <h1>Our Approach</h1>
        <p>
          At Angelo Consulting Group, we are committed to transforming your
          technology challenges into opportunities for growth. Our unique
          approach combines industry expertise, innovation, and a client-centric
          focus, ensuring that we deliver solutions tailored to your needs.
          Here's why you should choose us:
        </p>

        <div className="cards">
          <div className="cardss">
            <h2>Understanding Your Business</h2>
            <p>
              We start by gaining a comprehensive understanding of your business
              objectives, challenges, and opportunities. This deep dive allows
              us to tailor our solutions to your specific needs and align our
              efforts with your strategic goals.
            </p>
          </div>

          <div className="cardss">
            <h2>Collaborative Partnership</h2>
            <p>
              We view our clients as partners. By working closely with your
              team, we foster open communication and collaboration throughout
              the engagement. This approach ensures that our solutions are not
              only effective but also embraced by your organization.
            </p>
          </div>

          <div className="cardss">
            <h2>Customized Solutions</h2>
            <p>
              We believe in bespoke solutions. Our strategies and solutions are
              developed to address your unique requirements and challenges.
              Whether optimizing processes, implementing new technologies, or
              driving digital transformation, our solutions fit your
              organization like a glove.
            </p>
          </div>

          <div className="cardss">
            <h2>Practical Innovation</h2>
            <p>
              Innovation is at our core. We stay at the forefront of
              technological advancements and industry best practices to deliver
              solutions that drive your business forward. From AI and machine
              learning to cloud computing and cybersecurity, we leverage the
              latest technologies for measurable results.
            </p>
          </div>

          <div className="cardss">
            <h2>Execution Excellence</h2>
            <p>
              Great ideas need flawless execution. We emphasize rigorous
              implementation to ensure our solutions exceed expectations. Our
              experienced consultants bring expertise to every project, ensuring
              a smooth and successful implementation process.
            </p>
          </div>

          <div className="cardss">
            <h2>Continuous Improvement</h2>
            <p>
              The technology landscape is ever-evolving. We are dedicated to
              continuous improvement and learning. By regularly assessing our
              strategies and solutions, gathering feedback, and refining our
              approach, we ensure we deliver maximum value to our clients.
            </p>
          </div>

          <div className="cardss">
            <h2>Commitment to Quality</h2>
            <p>
              Quality is non-negotiable. We maintain the highest standards in
              everything we do—from client interactions to solution delivery.
              Our commitment to excellence guarantees that you receive
              exceptional service and results.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default about;
