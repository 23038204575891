import './home.css'
import MainHeader from '../../components/MainHeader'
import Choose from '../../components/Choose'
import StrategicFocus from '../../components/StrategicFocus'



const home = () => (
  <><MainHeader />
    <Choose />
    <StrategicFocus />
   
    
  </>
)

export default home